.button {
  color: var(--rolling-stone-dark);
}

.inlineLoader {
  position: relative;
}

.iconButton {
  position: relative;
  left: 4px;
}
