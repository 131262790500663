.formControlLabel {
  &Default {
    display: flex;
    margin-bottom: 4px;
    font-size: var(--title-default-size);
    line-height: var(--title-default-height);
    color: var(--shark);
  }

  &Required::after {
    content: "*";
    margin-left: 5px;
    color: var(--mauvelous);
  }

  &Tooltip {
    height: 20px;
    overflow: hidden;
    position: relative;
    top: -3px;
  }
}
