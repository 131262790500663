.errorBoundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & h1 {
    margin: 25px auto 40px;
    font-weight: bold;
    font-size: 29px;
    line-height: 32px;
    text-align: center;
  }

  & a {
    margin: 16px;
  }
}
