.content {
  position: relative;
}

.manualControls {
  padding: 16px;
}

.controls {
  display: flex;
  padding: 16px;

  button {
    margin-left: 16px;
  }
}

.graph {
  width: 100%;
  height: calc(100vh - 140px);
}
