.fileAnchor {
  &Wrapper {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
  }

  &Line {
    max-width: 100%;
    overflow: hidden;
    height: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    & > a {
      min-width: 102px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      & > svg {
        margin-right: 8px;
      }

      & > span {
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &Size {
    font-size: var(--title-small-size);
    line-height: var(--title-small-height);
    color: var(--rolling-stone-dark);
    margin-left: 8px;
  }

  &Description {
    margin-left: 24px;
  }
}
