.autoCompleteWrapper {
  position: relative;
  background-color: transparent;

  .autoCompleteInput {
    width: 100%;
    height: 32px;
  }
}

.autoCompleteOptionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    margin-left: 8px;
    flex-shrink: 0;
  }
}

.autoCompleteTooltip {
  max-width: none !important;
  max-height: 500px;
  overflow: auto;
  padding: 0;
  border-radius: 2px;
  color: var(--shark);
  box-shadow: 0 4px 8px -2px var(--silver-light), 0 0 1px var(--silver);
  background: white;

  :global(.tippy-content) {
    padding: 0;
  }
}

.loader {
  position: absolute;
  left: auto;
  right: 10px;
}
