.etNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  h1 {
    text-align: center;
    color: var(--shark);
    font-size: 29px;
    line-height: 32px;
  }
  &Content {
    color: var(--rolling-stone-dark);
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
  }
  &Button {
    margin-top: 16px;
  }
}
