.banner {
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  border-radius: 4px;

  &info {
    background-color: var(--selago);
  }

  &success {
    background-color: var(--zanah);
  }

  &error {
    background-color: var(--remy);
  }

  &warning {
    background-color: var(--china-ivory);
  }

  &Icon {
    margin-right: 16px;
    flex-shrink: 0;
    height: 24px;
  }

  &Title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-right: 40px;

    &Only {
      font-size: 14px;
    }
    p {
      margin: 0;
    }
  }

  &Content {
    margin-top: 4px;
    line-height: 20px;

    &Only {
      margin-top: 0;
      font-weight: 600;
    }

    & > * {
      word-break: break-word;
    }
  }

  &CloseBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: none;
    padding: 6px;
    cursor: pointer;
    color: var(--rolling-stone-dark);
    transition: 0.2s color;

    &:hover {
      color: var(--shark);
    }
  }
}
