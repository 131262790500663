.dateRangeWrapper {
  width: 100%;
  position: relative;

  :global {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.textInputClearButton {
  position: absolute;
  width: 16px;
  height: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 8px;
  color: var(--rolling-stone-dark);
}
