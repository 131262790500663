.syncStatus {
  &Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    & *:not(:first-child) {
      margin-left: 6px;
    }
  }
}

.Synchronized {
  color: var(--de-york);
}

.InProgress {
  color: var(--corn);
}

.Pending {
  color: var(--submarine);
}

.Failed {
  color: var(--mauvelous);
}
