.button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  width: auto;
  min-width: var(--one-column-width);
  padding: 6px 16px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: var(--input-default-radius);
  font-size: var(--title-default-size);
  line-height: var(--title-default-height);
  cursor: pointer;
  transition: background-color, color 0.15s linear;

  &Content {
    display: flex;
    align-items: center;
  }

  &Icon {
    position: relative;
    &left {
      right: 8px;
    }

    &right {
      left: 8px;
      order: 1;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.large {
  height: 40px;
}

.medium {
  height: 32px;
}

.filled {
  background-color: var(--forest-green);
  color: white;

  &:hover {
    background-color: var(--green-pea);
  }

  &:disabled {
    color: var(--submarine);
    background-color: var(--porcelain);
    pointer-events: none;
  }
}

.outlined {
  background-color: white;
  color: var(--forest-green);
  border: 1px solid var(--forest-green);

  &:hover {
    background-color: var(--zanah);
  }

  &:disabled {
    color: var(--submarine);
    border-color: var(--porcelain);
    pointer-events: none;
  }
}

.inline {
  border: none;
  background: none;
  width: auto;
  height: auto;
  padding: 0;
  font-weight: 600;
  min-height: 24px;
  color: var(--forest-green);
  transition: color 0.15s linear;

  &:hover {
    color: var(--green-pea);
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .button {
    &Icon {
      position: relative;
      &left {
        right: 0;
        margin-right: 8px;
      }

      &right {
        left: 0;
        margin-left: 8px;
        order: 1;
      }
    }
  }
}
