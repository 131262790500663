.toggleWrapper {
  position: relative;

  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  width: 44px;

  .checkboxInput {
    opacity: 0;
    position: absolute;

    &:checked + .toggle {
      transform: translateX(50%) translateX(-21px);
    }
    &:disabled + .toggle {
      background: #ccc;

      .toggleHandle {
        background-color: #6c6c6c;
      }
    }
  }

  .checkboxInput + .toggle {
    transform: translateX(5px);
  }

  .toggle {
    color: #fff;
    display: inline-block;
    height: 100%;
    left: -100%;
    position: relative;
    transition: 100ms linear;
    width: 200%;

    .toggleHandle {
      background: #fff;
      border-radius: 50%;
      display: inline-block;
      left: 48%;
      position: relative;
      top: 2px;
      height: 20px;
      width: 20px;
      z-index: 3;
    }
  }

  .onstyle {
    background-color: var(--zanah);

    .toggleHandle {
      background-color: var(--forest-green);
    }
  }

  .offstyle {
    background-color: var(--porcelain);
  }
}

.checkboxMessage {
  padding-left: 15px;
}

