.etPasswordInput {
  &VisibilityButton {
    background-color: transparent;
    border: unset;
    padding: 0;
    position: absolute;
    cursor: pointer;
    right: 13px;
    top: calc(50% - 12px);
    font-size: 0;
  }
}
