.userProfile {
  margin-left: 21px;
  display: flex;
  align-items: center;

  &Name {
    margin-right: 8px;
    max-width: 180px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .userProfileLogoutButton {
    background-color: transparent;
    color: var(--rolling-stone-dark);

    &:hover {
      color: var(--forest-green);
    }
  }
}
