:root {
  --primary-color: #29b24d;
  --primary-light-color: #59d87a;
  --secondary-color: #007aff;
  --secondary-hover-color: #4794ee;
  --dark-color: #000;
  --grey-darken-color: #3e4357;
  --grey-dark-color: #687292;
  --grey-title-color: #7d7f86;
  --grey-background-color: #e5e8ed;
  --grey-icon-color: #d0d4e2;
  --neutral-first-color: #f2f3f5;
  --neutral-second-color: #f8f7fc;
  --neutral-third-color: #e0e2ff;
  --grey-border-color: #dcdcdc;
  --disabled-border-color: #e4e4e4;
  --hover-border-color: #adb5c5;
  --disabled-text-color: #959cb2;
  --body-background-color: #f4f6fd;
  --disabled-background-color: #f2f3f5;
  --light-text-color: #fff;
  --light-background-color: #fff;

  --submarine: #b5bdc2;
  --abbey: #545558;
  --selago: #f4f6fd;
  --azure-radiance: #007aff;
  --cinnabar: #e64040;
  --red-dark: #9e1e1e;
  --athens-gray: #ecedf1;
  --oslo-gray: #8a9297;
  --rolling-stone: #747577;
  --rolling-stone-dark: #63696f;
  --forest-green: #27a83b;
  --forest-green-light: #29b24d;
  --shark: #222324;
  --porcelain: #e1e5e7;
  --light-porcelain: #e8ecee;
  --de-york: #7dcb89;
  --green-pea: #23702f;
  --zanah: #d4eed8;
  --mauvelous: #f08c8c;
  --remy: #fad9d9;
  --china-ivory: #fcf1cd;
  --silver: #bdbdbd;
  --silver-light: #c8c8c8;
  --gold-dark: #cd9600;
  --blue-lightest: #cce4ff;
  --blue-dark: #0b54a4;
  --corn: #eec200;
  --purpleLightest: #e9cdf0;
  --purpleDarkest: #421650;
  --orangeLightest: #fce7cc;
  --orangeDark: #bb7619;
}
