.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &IconCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    margin-bottom: 12px;
    border-radius: 50%;
    background-color: var(--porcelain);
    color: var(--submarine);
  }

  &Title {
    display: block;
    font-size: 20px;
    line-height: var(--title-large-height);
    font-weight: 700;
    color: var(--submarine);
    margin-bottom: 4px;
  }

  &Subtitle {
    display: block;
    font-size: var(--title-large-size);
    line-height: var(--title-large-height);
    color: var(--submarine);
  }
}
