.card {
  &Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    background: #FFFFFF;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    box-shadow: 0 4px 8px -2px rgba(34, 35, 36, 0.25), 0px 0px 1px rgba(34, 35, 36, 0.3);
  }

  &Header {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &Left {
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: 12px;
      height: 24px;

    }

    &Right {
      display: flex;
      flex-direction: row;
      justify-content: right;
    }

    &CollapseButton {
      &Down {
        transform: rotate(180deg);
      }
    }

    &Title {
      &Plate {
        line-height: 26px;
        text-transform: uppercase;

        padding: 2px 8px;
      }

      &Text {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        height: 24px;
        color: var(--shark);
      }
    }

    &ActionsTooltip {
      overflow: auto;
      border-radius: 2px;
      box-shadow: 0 4px 8px -2px var(--silver-light), 0 0 1px var(--silver);
      background-color: white !important;
      z-index: 4;

      :global(.tippy-content) {
        padding: 0;
      }

      &OptionsContainer {
        margin: 0;
        padding: 0;

      }

      &Action {
        color: var(--rolling-stone-dark);
        background-color: white;
        width: 100%;

        &:hover {
          background-color: var(--selago);
          color: var(--shark);
        }
      }
    }
  }

  &Body {
    width: 100%;
    padding: 0 16px 16px;

    &Header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &Title {
      display: block;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: var(--shark);
    }
  }

  &Footer {
    width: 100%;
    padding: 16px;

    border: 2px solid black;
  }
}

.hidden {
  display: none;
}

.extraPadding {
  padding-top: 16px;
}
