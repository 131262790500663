.dateRangeTextInput {
  width: 100%;
  height: 32px;
}

.dateRangeClearButton {
  position: absolute;
  width: 16px;
  height: auto;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 8px;
  color: var(--rolling-stone-dark);
}

.dateRangeTooltip {
  height: auto;
  overflow: auto;
  padding: 0;
  border-radius: 0.3rem;
  color: var(--shark);
  box-shadow: 0 4px 8px -2px var(--silver-light), 0 0 1px var(--silver);
  background: white;

  :global(.tippy-content) {
    padding: 0;
  }
}

.dateRangeCalendar {
  border: 1px solid var(--submarine);
  margin-bottom: -5px;

  :global {
    .react-datepicker__triangle {
      border-bottom-color: var(--body-background-color);

      &:before {
        border-bottom-color: var(--submarine);
      }
    }

    .react-datepicker__navigation {
      top: 14px;
      line-height: var(--title-large-height);
    }

    .react-datepicker__navigation--previous {
      border-right-color: var(--rolling-stone-dark);
    }

    .react-datepicker__navigation--next {
      border-left-color: var(--rolling-stone-dark);
    }

    .react-datepicker__header {
      background-color: var(--body-background-color);
      border-bottom: 1px solid var(--submarine);

      .react-datepicker__current-month {
        color: var(--shark);
        font-weight: bold;
        font-size: var(--title-large-size);
        line-height: var(--title-large-height);
      }

      .react-datepicker__day-name {
        color: var(--shark);
        font-size: var(--title-default-size);
      }
    }

    .react-datepicker__day {
      color: var(--shark);
      font-size: var(--title-default-size);
    }

    .react-datepicker__day.react-datepicker__day--disabled {
      color: var(--submarine);
    }

    .react-datepicker__day.react-datepicker__day--today {
      background-color: var(--porcelain);
      border-radius: 0.3rem;
    }

    .react-datepicker__day.react-datepicker__day--selected,
    .react-datepicker__day.react-datepicker__day--in-range,
    .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--keyboard-selected,
    .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--keyboard-selected,
    .react-datepicker__day.react-datepicker__day--in-selecting-range {
      color: white;
      background-color: var(--secondary-color);
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      color: var(--shark);
      background-color: transparent;
    }
  }
}
