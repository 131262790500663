.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: var(--submarine);
  cursor: pointer;
  transition: color 0.15s;

  &:hover {
    color: var(--rolling-stone-dark);
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
