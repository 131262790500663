@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.etLoader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  visibility: visible;
  &Blocking,
  &Fullscreen {
    background-color: rgba(#fff, 0.5);
  }

  &Blocking {
    z-index: 4;
  }

  &Fullscreen {
    position: fixed;
    z-index: 200;
  }

  &Spinner {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    animation: spin 1s linear infinite;
  }
}
