.assigneeCellWrapper {
  position: relative;
}

button.assigneeAddButton {
  border: none;
  color: var(--rolling-stone-dark);
  background-color: transparent;

  &:hover {
    background-color: var(--athens-gray);
  }
}

button.reAssignButton {
  padding: 6px 0;
  border: none;
  min-width: auto;
  max-width: 100%;
  background-color: transparent;
  color: var(--shark);

  &:hover {
    background-color: transparent;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    left: 0;
    flex-shrink: 0;
    color: var(--rolling-stone-dark);
  }
}
