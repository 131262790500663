.selectFilterInput {
  & > div {
    height: 32px;
    padding: 5px var(--input-padding-default);
    font-weight: normal;

    &:before {
      bottom: 12px;
    }
  }
}

.selectFilterList {
  label {
    color: var(--rolling-stone-dark);
  }
}
