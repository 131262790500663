.notificationBell {
  position: relative;
  color: var(--rolling-stone-dark) !important;
}

.tooltip {
  :global {
    .tippy-content {
      padding: 0;
      min-height: 24px;
    }
  }
}

.webNotification {
  position: relative;

  &Container {
    width: 360px;
    background-color: white;
    border-radius: 4px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
    padding-top: 16px;
    display: block;
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 0 16px;

    .markBtn {
      font-size: 12px;
      line-height: 16px;
      color: var(--submarine);
      justify-content: flex-end;
      padding-right: 12px;

      &:hover {
        color: var(--rolling-stone-dark);
      }
    }

    .notificationCounter {
      span {
        &:first-of-type {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: var(--shark);
        }

        &:nth-child(2) {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--azure-radiance);
          position: relative;
          top: -2px;
          left: 1px;
        }
      }
    }
  }

  &Items {
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    max-height: calc(100vh - 160px);

    .notificationItem {
      position: relative;
      padding: 4px 16px;
      margin-bottom: 12px;
      cursor: pointer;

      &:hover {
        background-color: var(--selago);
      }

      &Circle {
        width: 7px;
        height: 7px;
        background-color: var(--azure-radiance);
        border-radius: 50%;
        margin: 1px 0 1px 7px;
      }

      &Remove {
        position: absolute;
        top: -10.5px;
        right: -10.5px;

        &Unread {
          right: 3.5px;
        }
      }

      &Date {
        font-size: 12px;
        line-height: 16px;
        color: var(--rolling-stone-dark);
        white-space: nowrap;
        float: right;
      }

      & > p {
        width: 90%;
        margin: 0 0 5px;
        font-size: 14px;
        line-height: 20px;
        color: var(--rolling-stone-dark);
        min-height: 40px;
        white-space: pre-wrap;

        a {
          color: var(--azure-radiance);
        }

        b,
        strong {
          font-weight: 800;
        }
      }

      &Info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &Top {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 4px;
        right: 16px;
      }
    }
  }

  &Empty {
    max-width: 280px;
    margin: auto;
    padding: 5px 0 15px;
    text-align: center;
    font-size: var(--title-large-size);
    line-height: var(--title-large-height);
    color: var(--submarine);
  }
}

.unreadMarker {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--azure-radiance);
  top: 2px;
  left: 2px;
}
