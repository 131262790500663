.recordsSidebar {
  display: flex;
  min-width: var(--three-columns-width);
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 32px;

  &Tabs {
    padding: 0 32px;
  }

  &Title {
    margin: 0 32px;
    font-size: var(--title-large-size);
    line-height: var(--title-large-height);
    font-weight: 700;
  }
}

.emptyList {
  margin-top: 160px;
}

.transactionRecordsList{
  overflow: auto;

  &Container {
    box-shadow: 0 4px 8px -2px rgba(34, 35, 36, 0.25), 0px 0px 1px rgba(34, 35, 36, 0.3);
    padding: 16px;
    margin: 16px;
  }

  &Item {
    padding-bottom: 16px;
    padding-top: 16px;

    &Main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &Secondary {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 8px;

      &Item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 8px
}

.dateItem {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  color: var(--rolling-stone-dark);

  .dateLine {
    font-size: 14px;
    line-height: 20px;
    text-align: right;
  }

  .timeline {
    font-size: 14px;
    line-height: 20px;
    text-align: right;
  }
}

.bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--shark);
}

.withBorder {
  border-bottom: 1px solid var(--porcelain);
}
