.record {
  margin-top: 16px;

  &Header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: 20px;
  }

  &Author {
    font-weight: 600;
  }

  &Date {
    font-size: 12px;
    color: var(--rolling-stone-dark);
    flex-grow: 1;
    text-align: right;
  }

  &Text {
    margin-top: 4px;
    line-height: 20px;
    max-height: 104px;
    white-space: pre-wrap;
    word-break: break-word;
    overflow: hidden;

    &Expanded {
      max-height: none;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
}
