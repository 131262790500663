.navigation {
  position: relative;
  width: 240px;
  flex-shrink: 0;
  transition: width 0.15s cubic-bezier(0.76, 0.23, 0.61, 0.87);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.navigationWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-top: 15px;
  width: 240px;
  padding: 0 12px;

  ul {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #cfcfcf;
      border: 1px solid #aaa;
    }
  }

  & > .navigationCollapseButton {
    display: none;
  }

  & .navigationLink {
    color: var(--rolling-stone-dark);
  }

  & .navigationLinkActive {
    color: var(--light-text-color);
  }
}

@media screen and (max-width: 1024px) {
  .navigationCollapsed {
    width: 56px;
  }

  .navigationLinkCollapsed {
    justify-content: center;

    & .navigationLinkIconCollapsed {
      margin-right: 0;
    }
  }

  .navigationWrapper {
    & .navigationCollapseButton {
      display: block;
      border: none;
      background-color: transparent;
      color: var(--rolling-stone-dark);
      font-weight: 600;

      &:hover {
        background-color: transparent;
        color: var(--forest-green);
      }
    }

    & .navigationButtonCollapsed {
      width: 40px;
      min-width: auto;
      padding: 6px 10px;
      height: 40px;

      & svg {
        right: auto;
      }
    }
  }

  .navigationWrapperCollapsed {
    width: 56px;
    padding: 0 8px;
    ul {
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
}
