.recordsList {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &Empty {
    margin-top: 160px;
  }

  &AddRecordBtn {
    margin-top: auto;
    width: 100%;
  }

  &Search {
    flex-grow: 1;
  }

  &TopControls {
    display: flex;
    align-items: center;
    padding: 16px 32px;
  }

  &BottomControls {
    margin-top: auto;
    padding: 16px 32px;
  }

  &Content {
    padding: 16px 32px;
    overflow: auto;
    max-height: calc(100vh - 196px);

    &Scrollable {
      padding-right: 24px;
    }
  }

  &NewNoteMessage {
    text-align: right;
  }

  &.recordsListNoRecords {
    .recordsListContent {
      max-height: calc(100vh - 124px);
    }
  }
}

.recordsListEditable {
  .recordsListContent {
    max-height: calc(100vh - 268px);
  }

  &.recordsListNoRecords {
    .recordsListContent {
      max-height: calc(100vh - 196px);
    }
  }
}

.recordsListOverflowed {
  .recordsList {
    &TopControls {
      box-shadow: 0px 4px 8px -2px rgba(34, 35, 36, 0.25),
        0px 0px 1px rgba(34, 35, 36, 0.3);
    }

    &BottomControls {
      box-shadow: 0px -2px 8px -1px rgba(34, 35, 36, 0.25),
        0px 0px 1px rgba(34, 35, 36, 0.3);
    }
  }
}
