.fileUploader {
  display: flex;
  flex-direction: column;

  &ListOnTop {
    > div:last-child {
      order: -1;
    }
  }
}

.dropzoneBlock {
  position: relative;
  height: 120px;
  margin-bottom: var(--gutter-width);
  border: 1px dashed var(--submarine);
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.15s ease;
}

.dropzoneActive {
  background-color: var(--zanah);
}

.dropzoneDisabled {
  background-color: var(--porcelain);
}

.dropzoneBlockText {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--rolling-stone-dark);
  font-weight: 600;
  font-size: var(--title-default-size);
  line-height: var(--title-default-height);
}

.dropzoneUploadButton {
  font-weight: 600;
  padding: 0 5px 0 10px;
  min-width: auto;
  height: 26px;
  border: none;
  color: var(--forest-green-light);
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}

.dropzoneHintText {
  margin-top: 6px;
  padding: 0 8px;
  text-align: center;
  color: var(--rolling-stone-dark);
  font-size: var(--title-default-size);
  line-height: var(--title-default-height);
}

.dropzoneErrorText {
  display: block;
}
