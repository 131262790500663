@import "./colors";
@import "./vars";

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap");

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: var(--shark);
  background: var(--body-background-color);
  overscroll-behavior-y: none;
  scrollbar-width: thin;
}

div,
button,
input,
optgroup,
select,
textarea,
pre {
  font-family: "Nunito Sans", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

#root {
  height: 100%;
}

a {
  transition: color 0.2s;
  text-decoration: none;
  color: var(--forest-green);

  &:hover {
    text-decoration: underline;
  }
}

h1 {
  margin: 12px 0;
  font-size: 35px;
  line-height: 40px;
  font-weight: bold;
}

h2 {
  margin: 12px 0;
  font-size: 29px;
  line-height: 32px;
  font-weight: bold;
}

h3 {
  margin: 12px 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}

h4 {
  margin: 12px 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}

pre {
  white-space: pre-wrap;
}

svg {
  fill: currentColor;
}

input::-ms-clear {
  display: none;
}

input:focus {
  outline: none;
}

input:-moz-ui-invalid {
  box-shadow: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::placeholder {
  color: var(--submarine);
}

:-ms-input-placeholder {
  color: var(--submarine);
}

::selection {
  background-color: var(--primary-light-color);
  color: var(--light-text-color);
}

::-webkit-scrollbar-track {
  background-color: var(--neutral-first-color);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 2px;
  background-color: var(--neutral-first-color);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: var(--grey-border-color);
}
