.etHeader {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 4px 8px -2px rgba(34, 35, 36, 0.25),
    0px 0px 1px rgba(34, 35, 36, 0.3);

  &Logo {
    &Image {
      width: 80px;
      vertical-align: top;
    }
  }
}
