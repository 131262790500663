.etLogo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-background-color);
  box-shadow: 0px 2px 22px 4px var(--light-porcelain);
  width: 450px;
  padding: 48px 40px 40px;
  margin: 80px auto 0;
  border-radius: var(--border-radius-default);
  min-height: 630px;
  &Wrapper {
    display: flex;
    padding: 20px;
  }

  &Image {
    width: 120px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .etLogo {
    width: 100%;
    margin: 0;
    padding: 20px 10px;
    min-height: 100vh;

    &Wrapper {
      padding: 0;
    }
  }
}
