.loginView {
  &Content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  &Button {
    width: 180px;
  }

  &Block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
}
