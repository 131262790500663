.etPortalView {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;

  &Container {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background: white;
    overflow-x: auto;
  }
}
