.headerSearchBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 16px;
  width: 100%;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 100%;
}

.searchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0;
  width: 100%;
  position: relative;
}

.searchTextInput {
  padding-left: 40px !important;
}

.searchInputIcon {
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
}

.searchResultsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px;
  gap: 16px;
}

.searchResultsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;

  p {
    margin: 0;
  }
}

.searchResultsSectionTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 16px 0;
}

.searchResultsSectionResultsCount {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;
}

.searchResultsItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
  width: 100%;
  border-bottom: 1px solid var(--porcelain);

  &:last-of-type {
    border: none;
  }

  &:hover {
    background-color: var(--selago);
    text-decoration: none;
  }

  .searchResultsItemColumn {
    display: flex;
    flex-direction: column;
    color: var(--shark);
    width: 200px;
  }

  .itemTitle {
    width: 200px;
    color: var(--forest-green);
  }

  .lastItem {
    margin-left: auto;
    width: auto;
  }

  .subtitleContainer {
    flex-direction: row;
    display: flex;
    column-gap: 5px;
  }

  .subtitle {
    color: var(--rolling-stone-dark);
    font-size: 12px;
  }
}

.emptyState {
  padding: 32px 0 16px 0;
}
