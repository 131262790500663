.formControlDropdown {
  &OptionWrapper {
    list-style: none;
    margin: 0;
    padding: 4px 0;
    background: white;
    border: 1px solid rgba(127, 139, 164, 0.4);
    box-shadow: 0 4px 8px -2px rgba(34, 35, 36, 0.25),
      0px 0px 1px rgba(34, 35, 36, 0.3);
    border-radius: 2px;
    color: var(--shark);
    max-height: 192px;
    overflow: hidden auto;

    &Item {
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 6px 12px;
      color: var(--rolling-stone-dark);

      &:hover {
        background: var(--body-background-color);
        color: var(--shark);
      }

      &.active {
        color: var(--shark);
      }

      &.selected {
        background: var(--body-background-color);
      }
    }

    &Empty {
      pointer-events: none;
      color: var(--submarine);
    }
  }
}
