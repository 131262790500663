.etDialog {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(74, 74, 74, 0.87);

  &Enter {
    opacity: 0;

    .etDialogWindow {
      transform: translateY(-100px);
    }

    &.etDialogEnterActive {
      opacity: 1;
      transition: all 0.2s ease-in;

      .etDialogWindow {
        transition: all 0.2s ease-in;
        transform: translateY(0);
      }
    }
  }

  &Leave {
    opacity: 1;

    .etDialogwindow {
      transform: translateY(0);
    }

    &.etDialogLeaveActive {
      opacity: 0;
      transition: all 0.2s ease-in;

      .etDialogWindow {
        transition: all 0.2s ease-in;
        transform: translateY(-100px);
      }
    }
  }

  &Window {
    display: block;
    position: relative;
    border-radius: 4px;
    background: var(--light-background-color);
    width: 480px;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
  }

  &Content {
    padding: 12px 24px;
    max-height: calc(100vh - 200px);
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
      background: transparent;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #cfcfcf;
      border: 1px solid #aaa;
    }

    &Actionless {
      min-height: 100px;
      border-radius: 0 0 4px 4px;
      max-height: calc(100vh - 160px);
    }

    &Headerless {
      border-radius: 4px 4px 0 0;
      max-height: calc(100vh - 120px);
    }

    &ActionAndHeaderless {
      border-radius: 4px;
      max-height: calc(100vh - 80px);
    }
  }

  &Header {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 72px;
    font-weight: bold;
    border-radius: 4px 4px 0 0;
    padding: 16px 24px;
    margin: 0;
    font-size: 20px;
    letter-spacing: 0.2px;

    &Title {
      display: flex;
      width: 100%;
      color: var(--shark);
    }

    &Hidden {
      min-height: 0;
      padding: 0;
    }

    &Shadowed {
      z-index: 1;
      box-shadow: 0px 4px 8px rgba(34, 35, 36, 0.25),
        0px 0px 1px rgba(34, 35, 36, 0.3);
    }
  }

  &CloseButton {
    display: flex;
    position: absolute;
    cursor: pointer;
    border: none;
    background: none;
    margin: 0;
    padding: 6px;
    right: 5px;
    top: 5px;
    color: var(--rolling-stone-dark);
    transition: color 0.2s;

    &:hover {
      color: var(--shark);
    }
  }

  &Controls {
    position: relative;
    display: flex;
    background-color: var(--light-background-color);
    padding: 16px 24px;
    border-radius: 0 0 4px 4px;

    > * {
      margin-right: 14px;
    }

    > :last-child {
      margin-right: 0;
    }

    &Shadowed {
      z-index: 1;
      box-shadow: 0px -2px 8px rgba(34, 35, 36, 0.25),
        0px 0px 1px rgba(34, 35, 36, 0.3);
    }
  }
}

@media screen and (max-width: 768px) {
  .etDialog {
    &Header,
    &Controls {
      padding: 20px 10px;
    }

    &Content {
      padding: 10px;
    }
  }
}
