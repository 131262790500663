
.options {
  &Container {
    margin: 0;
    padding: 0;
  }

  &Item {
    color: var(--shark);
    list-style: none;
    cursor: pointer;
    font-weight: 600;
    size: 14px;
    line-height: 20px;
    position: relative;
    width: 100%;
    padding: 14px 16px 12px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-radius: 2px;
    background-color: transparent;
    transition-duration: 0.2s;
    margin-top: -1px;

    &:hover, &:focus {
      background-color: var(--body-background-color);
    }
  }
}

.switcherTooltip {
  overflow: auto;
  border-radius: 2px;
  box-shadow: 0 4px 8px -2px var(--silver-light), 0 0 1px var(--silver);
  background-color: white !important;

  :global(.tippy-content) {
    padding: 0;
  }
}

.selected {
  background-color: var(--body-background-color);
}
