.helpPortal {
  margin-bottom: 20px;
  padding: 0 12px;
  &Collapsed {
    padding: 0 8px;
  }
  .helpPortalBtn {
    justify-content: flex-start;
    padding: 6px 14px;
    color: var(--rolling-stone-dark);
    min-width: unset;
    margin: 0;
    display: flex;
    align-items: center;
    svg {
      min-width: 20px;
    }
    &Collapsed {
      padding: 6px 10px;
    }
    &:hover {
      span {
        color: var(--rolling-stone-dark);
      }
    }
    span {
      margin-left: 10px;
    }
  }
}
