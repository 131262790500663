.collapse {
  position: relative;
  max-width: 30%;
  flex-basis: 30%;
  border-left: 1px solid var(--porcelain);
  overflow: hidden;
  flex-shrink: 0;
  transition: flex-basis 0.15s;

  &Collapsed {
    width: 55px;
    flex-basis: 55px;
  }

  &Button {
    position: absolute;
    top: 32px;
    left: 3px;
    transition: all 0.15s;
    width: 24px;
    height: 24px;
    z-index: 1;

    &:focus {
      outline: 1px solid var(--forest-green);
    }

    &Left {
      transform: rotate(180deg);
    }
  }
}
