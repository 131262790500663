.etTabs {
  display: flex;
  position: relative;
  height: 32px;
  border-bottom: 1px solid var(--porcelain);

  &Marker {
    transition: left 0.2s, width 0.2s;
    position: absolute;
    height: 2px;
    bottom: -1px;
    background-color: var(--forest-green);
  }
}

.etTab {
  margin-right: 16px;
  color: var(--rolling-stone-dark);
  user-select: none;
  padding-bottom: 8px;

  &Link {
    position: relative;
    cursor: pointer;
    display: inline-block;
    line-height: 24px;
    font-weight: 600;
    color: var(--rolling-stone-dark);
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }

    &:hover:not(.etTabLinkActive) {
      color: var(--rolling-stone);
    }

    &Active {
      color: var(--forest-green);
    }

    &Disabled {
      outline: none;
      cursor: default;
      pointer-events: none;
      color: var(--oslo-gray);
    }

    &:focus {
      outline: none;

      &:after {
        position: absolute;
        content: " ";
        top: 0px;
        left: -8px;
        width: calc(100% + 16px);
        height: calc(100% - 0px);
        border-radius: 6px;
        border: 2px solid var(--de-york);
      }
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
}
