.navigationLink {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 42px;
  transition: all 0.2s ease;
  color: var(--shark);
  font-size: 14px;
  font-weight: 600;
  border-radius: 2px;

  &:hover {
    text-decoration: none;
  }

  &Active {
    color: var(--light-text-color);
    background-color: var(--forest-green);

    &:hover {
      color: var(--light-text-color);
    }

    .navigationLinkArrow {
      display: block;
    }
  }

  &Disabled {
    pointer-events: none;
    color: var(--submarine);
  }

  &Arrow {
    display: none;
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
  }

  &Icon {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &:hover:not(.navigationLinkActive) {
    color: var(--forest-green);
  }
}
