.dataGrid.ag-theme-alpine {
  min-height: 300px;
  color: var(--shark);
  font-size: 14px;

  .ag-header-cell,
  .ag-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ag-cell-label-container {
    height: auto;
    color: var(--shark);
  }

  .ag-header-container {
    background-color: white;
    position: relative;
    z-index: 1;
  }

  .ag-header-row {
    height: 32px;
    font-weight: 600;

    &:not(:first-child) .ag-header-cell {
      border-top: none;
    }
  }

  .ag-row-odd:not(.ag-row-selected):not(.ag-row-hover) {
    background-color: var(--light-background-color);
  }

  .ag-row-hover,
  .ag-row-selected {
    cursor: pointer;
    background-color: var(--selago);
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-header {
    background-color: var(--light-background-color);
    border: none;
  }

  .ag-cell {
    line-height: 50px;
  }

  .ag-react-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .ag-filter-icon {
    display: none;
  }

  .ag-body-viewport::-webkit-scrollbar-track {
    background-color: var(--light-background-color);
  }

  .ag-checkbox-input-wrapper {
    position: relative;
    left: -4px;
    font-size: 18px;
    line-height: 18px;
    width: 18px;
    height: 18px;

    input {
      cursor: pointer;
      position: relative;
      padding: 16px;
      width: calc(100% + 32px);
      height: calc(100% + 32px);
      left: -16px;
      top: -16px;
      z-index: 1;
    }

    &:after {
      color: var(--submarine);
    }

    &:hover:after {
      color: var(--rolling-stone);
    }

    &.ag-indeterminate:after,
    &.ag-checked:after {
      color: var(--primary-color);
    }
  }
}
