$aside-width: 240px;
$padding-default: 16px;
$max-content-width: 1440px;
$box-shadow-default: 0 2px 2px 0 #e8ecee;
$title-small-size: 12px;
$title-small-height: 16px;
$title-default-size: 14px;
$title-default-height: 20px;
$title-large-size: 16px;
$title-large-height: 24px;
$input-default-radius: 2px;
$input-padding-default: 12px;

$column-width: 102px;
$gutter-width: 16px;

$one-column-width: $column-width;
$two-columns-width: calc(2 * #{$column-width} + #{$gutter-width});
$three-columns-width: calc(3 * $column-width + 2 * $gutter-width);
$four-columns-width: calc(4 * #{$column-width} + 3 * #{$gutter-width});
$five-columns-width: calc(5 * #{$column-width} + 4 * #{$gutter-width});
$six-columns-width: calc(6 * #{$column-width} + 5 * #{$gutter-width});

:root {
  --aside-width: #{$aside-width};
  --padding-default: #{$padding-default};
  --max-content-width: #{$max-content-width};
  --box-shadow-default: #{$box-shadow-default};

  --title-small-size: #{$title-small-size};
  --title-small-height: #{$title-small-height};
  --title-default-size: #{$title-default-size};
  --title-default-height: #{$title-default-height};
  --title-large-size: #{$title-large-size};
  --title-large-height: #{$title-large-height};
  --input-default-radius: #{$input-default-radius};
  --input-padding-default: #{$input-padding-default};

  --gutter-width: #{$gutter-width};

  --one-column-width: #{$column-width};
  --two-columns-width: calc(#{$two-columns-width});
  --three-columns-width: calc(#{$three-columns-width});
  --four-columns-width: calc(#{$four-columns-width});
  --five-columns-width: calc(#{$five-columns-width});
  --six-columns-width: calc(#{$six-columns-width});
}
