.fileListWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.fileItemWrapper {
  margin-bottom: var(--gutter-width);
}

.fileItemTop {
  height: 24px;
  display: flex;
  align-items: center;
  color: var(--shark);
}

.fileItemError {
  color: var(--cinnabar);
}

.fileItemIcon {
  margin-right: 4px;
}

.fileItemTitle {
  font-size: var(--title-default-size);
  line-height: var(--title-default-height);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
}

.fileItemSize {
  flex-shrink: 0;
  font-size: var(--title-small-size);
  line-height: var(--title-small-height);
  color: var(--rolling-stone-dark);
  margin: 0 8px;
}

.fileItemErrorText {
  display: block;
  margin-top: 4px;
  font-size: var(--title-small-size);
  line-height: var(--title-small-height);
  color: var(--cinnabar);
}

.fileItemIconRight {
  margin-left: auto;
}

button.fileItemRemove {
  border: none;
  min-width: auto;
  height: 24px;
  margin: 0 0 2px;
  padding: 0;

  &:hover {
    background: none;
  }
}

.fileItemProgressBlock {
  display: flex;
  align-items: center;
  position: relative;
}

.fileItemProgressText {
  width: 40px;
  text-align: right;
  font-size: var(--title-small-size);
  line-height: var(--title-small-height);
  color: var(--rolling-stone-dark);
}

.fileItemProgressBar {
  position: relative;
  width: 124px;
  height: 4px;
  margin-right: 8px;
  background-color: var(--porcelain);
  border-radius: 2px;
  overflow: hidden;
}

.fileItemProgressFill {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: var(--forest-green);
  transition: width ease 0.1s;
}

.description {
  display: block;
  margin-left: 24px;
  color: var(--shark);
}
