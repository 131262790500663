.linkedBlock {
  display: flex;
  justify-content: flex-start;
  margin-top: 4px;
}

.transactions {
  display: flex;
  flex-direction: column;
}

.linkedTransaction {
  line-height: 16px;
  font-size: 12px;
  color: var(--forest-green);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.destination {
  line-height: 16px;
  font-size: 12px;
  color: var(--rolling-stone-dark);
}

.linkedIcon {
  flex-shrink: 0;
  margin: 2px 4px 0 0;
}
