.infoPlate {
  display: inline-flex;
  font-weight: 600;
  font-size: var(--title-small-size);
  line-height: var(--title-small-height);
  letter-spacing: 0.8px;
  padding: 2px 4px;
  border-radius: 2px;
  text-transform: uppercase;
}

.neutral {
  color: var(--rolling-stone-dark);
  background-color: var(--porcelain);
}

.info {
  color: var(--blue-dark);
  background-color: var(--blue-lightest);
}

.success {
  color: var(--green-pea);
  background-color: var(--zanah);
}

.warning {
  color: var(--gold-dark);
  background-color: var(--china-ivory);
}

.error {
  color: var(--red-dark);
  background-color: var(--remy);
}

.dark {
  color: white;
  background-color: var(--rolling-stone-dark);
}

.active {
  color: var(--forest-green);
  background-color: var(--zanah);
}

.success-dark {
  color: white;
  background-color: var(--green-pea);
}
.black {
  color: white;
  background-color: var(--shark);
}
.purple {
  color: var(--purpleDarkest);
  background-color: var(--purpleLightest);
}
.orange {
  color: var(--orangeDark);
  background-color: var(--orangeLightest);
}
.complete {
  color: white;
  background-color: var(--azure-radiance);
}
