.tooltipBlock {
  position: relative;
  display: flex;
  margin-left: 4px;
  cursor: pointer;
  min-width: 32px;
}

.tooltipBody {
  max-width: var(--four-columns-width);
  max-height: 500px;
  overflow: auto;
  padding: 5px 15px;
  border-radius: 4px;
  color: var(--shark);
  box-shadow: 0 4px 8px -2px var(--silver-light), 0 0 1px var(--silver);
  background: white;
}
