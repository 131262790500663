.formControl {
  &Message {
    margin-top: 4px;
    font-size: var(--title-small-size);
    line-height: var(--title-small-height);
  }

  &MessageError {
    color: var(--cinnabar);
  }

  &MessageHint {
    color: var(--rolling-stone);
  }
}
