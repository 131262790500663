.toastWrapper {
  background: var(--shark);
  padding: 12px 12px 12px 15px;
  border-radius: 2px;
  min-height: auto;
}
.etNotification {
  width: var(--three-column-width);
  padding: 0;
  user-select: none;

  &Button {
    cursor: pointer;
    margin-left: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: transparent;
    border: unset;
    outline: none;
  }

  &Info {
    font-family: "Nunito Sans", sans-serif;
    margin: 0 12px;
    color: white;
    font-size: 14px;
    line-height: 20px;
  }

  &Wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &Icon {
    flex-shrink: 0;
  }
}
