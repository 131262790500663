.selectWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.selectPlaceholder {
  display: block;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select {
  height: 40px;
  width: 100%;
  padding: 10px var(--input-padding-default);
  border: 1px solid var(--submarine);
  border-radius: var(--input-default-radius);
  font-size: var(--title-default-size);
  line-height: var(--title-default-height);
  color: var(--shark);
  background-color: white;
  box-sizing: border-box;
  transition: all 0.15s linear;
  cursor: pointer;
  padding-right: 35px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  outline: none;

  &:before {
    content: " ";
    position: absolute;
    bottom: 17px;
    right: 15px;
    width: 0;
    height: 0;
    margin: auto;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: var(--rolling-stone-dark) transparent transparent transparent;
    pointer-events: none;
    z-index: 1;
  }

  &:hover:not(:focus) {
    border: 1px solid var(--rolling-stone);
  }

  &:focus {
    border: 1px solid var(--azure-radiance);
  }

  &:disabled {
    color: var(--rolling-stone-dark);
    pointer-events: none;
  }

  &.selectEmpty {
    color: var(--submarine);
  }

  &.selectError:not(:focus) {
    border-color: var(--cinnabar);
  }

  &.disabled {
    background-color: var(--porcelain);
    border-color: var(--porcelain);
    color: var(--rolling-stone-dark);

    &Click {
      pointer-events: none;
    }

    &:hover {
      border: 1px solid var(--porcelain);
    }

    &:before {
      content: " ";
      border-color: var(--submarine) transparent transparent transparent;
    }
  }
}

.selectLoader {
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 24px;
  height: 24px;
}

.selectTooltip {
  max-width: 100% !important;
  max-height: 192px;
  overflow: auto;
  padding: 0;
  border-radius: 2px;
  color: var(--shark);
  box-shadow: 0 4px 8px -2px var(--silver-light), 0 0 1px var(--silver);
  background: white;

  :global(.tippy-content) {
    padding: 0;
  }
}

.selectOptionCheckbox {
  width: 100%;
}

.selectOptionCheckboxGroupWrapper {
  padding-left: calc(var(--input-padding-default) * 2);
  :global(.checkboxWrapper) {
    padding: 6px 12px;
  }
  .checkboxBlockWrapper {
    padding: 6px var(--input-padding-default);
    margin: 0;
  }
}
