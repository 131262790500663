.radioGroupWrapper {
  display: flex;
  align-items: center;
  width: auto;
  flex-wrap: nowrap;
}

.radioGroupWrapperBlock {
  height: auto;
  flex-direction: column;
  align-items: flex-start;

  &>.radioButtonWrapper {
    margin-right: 0;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title {
  /* Label */
  height: 20px;
  
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* Ink */
  color: #222324;

  padding-bottom: 12px;

}

.radioButtonWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.radioButtonInput {
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked+.radioButtonLabel {
    &:before {
      border: 2px solid var(--forest-green);
    }

    &:after {
      background-color: var(--forest-green);
    }
  }

  &:disabled+.radioButtonLabel {
    color: var(--submarine);

    &:before {
      border: 2px solid var(--porcelain);
    }
  }

  &:disabled:checked+.radioButtonLabel {
    color: var(--submarine);

    &:before {
      border: 2px solid var(--de-york);
    }

    &:after {
      background-color: var(--de-york);
    }
  }

  &:focus+.radioButtonLabel {
    &:before {
      border: 2px solid var(--rolling-stone);
    }
  }
}

.radioButtonLabel {
  position: relative;
  padding: 0 0 0 30px;
  font-size: var(--title-default-size);
  line-height: var(--title-default-height);
  color: var(--shark);
  cursor: pointer;
  transition: 0.15s all ease;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid var(--submarine);
    border-radius: 10px;
    transition: 0.15s all ease;
    box-sizing: border-box;
  }

  &:after {
    content: " ";
    position: absolute;
    top: 5px;
    left: 5px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    transition: 0.15s all ease;
  }

  &:hover {
    &:before {
      border: 2px solid var(--rolling-stone);
    }
  }
}

.radioButtonError:before {
  border-color: var(--cinnabar);
}

.radioGroupMessage {
  margin-top: 15px !important;
}

.radioGroupTable {
  width: 100%;
}

.radioGroupTableRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.radioGroupTableTitle {
  width: 60%;
}

.radioGroupTableHeaderText {
  color: var(--rolling-stone-dark);
  font-size: var(--title-default-size);
  line-height: var(--title-default-height);
}

.radioGroupTableVariants {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  &>* {
    width: 45px;
    display: flex;
    justify-content: center;
  }

  & label {
    padding: 0 0 0 21px;
  }
}

.radioGroupTableGroup {
  flex-grow: 1;
}

.radioGroupTableError {
  display: flex;
  justify-content: flex-end;
}