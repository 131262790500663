.textInput {
  &Wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &Inline {
    display: inline-flex;
    margin-right: var(--gutter-width);
  }

  &Default {
    height: 40px;
    width: 100%;
    min-width: var(--one-column-width);
    padding: 10px var(--input-padding-default);
    border: 1px solid var(--submarine);
    border-radius: var(--input-default-radius);
    font-size: var(--title-default-size);
    line-height: var(--title-default-height);
    color: var(--shark);
    background-color: white;
    box-sizing: border-box;
    transition: all 0.15s linear;
    &.passwordType {
      padding: 10px 40px 10px var(--input-padding-default);
    }

    &:hover:not(:focus):not([aria-invalid="true"]):not(:disabled) {
      border: 1px solid var(--rolling-stone);
    }

    &:focus {
      border: 1px solid var(--azure-radiance);
    }

    &:disabled {
      color: var(--rolling-stone-dark);
      pointer-events: none;
      border: 1px solid var(--porcelain);
      background: var(--porcelain);
    }

    &[aria-invalid="true"] {
      border: 1px solid var(--cinnabar);
    }
  }

  &Multiline {
    height: auto;
    min-height: 160px;
    resize: none;
    outline: none;
    vertical-align: top;
  }

  &FieldWrapper {
    position: relative;
  }
}
